

const RasporedZvona = ()=>{
    return(
        <div className="kontejner">
        <div className='kalendar'>
           <img src='../images/rasporedZvonjenja.jpg' className='img-fluid shadow-4' alt='Распоред звоњења 2023/2024 године'  />
        </div>
        </div>
    )
}

export default RasporedZvona;