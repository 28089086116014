import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';

const PticeMogaKraja = ()=>{
    return(
        <div className='kontejner'>
          <div className='textSlida'>
          <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
          </div>
        <div className='kontejnerSlida'>
             <MDBCarousel showIndicators showControls fade>
      <MDBCarouselItem itemId={1}>
        <img src='../images_vesti/projekatBiologija/ptice1.jpg' className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
          <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={2}>
        <img src='images_vesti/projekatBiologija/ptice2.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={3}>
        <img src='images_vesti/projekatBiologija/ptice3.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={4}>
        <img src='images_vesti/projekatBiologija/ptice4.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={5}>
        <img src='images_vesti/projekatBiologija/ptice5.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={6}>
        <img src='images_vesti/projekatBiologija/ptice6.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={7}>
        <img src='images_vesti/projekatBiologija/ptice7.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={8}>
        <img src='images_vesti/projekatBiologija/ptice8.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={9}>
        <img src='images_vesti/projekatBiologija/ptice9.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={10}>
        <img src='images_vesti/projekatBiologija/ptice10.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={11}>
        <img src='images_vesti/projekatBiologija/ptice11.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={12}>
        <img src='images_vesti/projekatBiologija/ptice12.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={13}>
        <img src='images_vesti/projekatBiologija/ptice13.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={14}>
        <img src='images_vesti/projekatBiologija/ptice14.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={15}>
        <img src='images_vesti/projekatBiologija/ptice15.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Ученици 6. разреда,уз помоћ наставнице Самире Обућине, истраживали су птице, њихов начин живота, 
            факторе који их угрожавају и како их можемо заштитити. Знање и креативност на делу!</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

    </MDBCarousel>
        </div>
        </div>
    )
}

export default PticeMogaKraja;