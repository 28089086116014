const RasporedPismenih = ()=>{
    return(
        <div className="textR">
        <div >
        <span>Старији разреди - друго полугодиште</span>
           <img src='../images/pismene2pol1.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismene2pol2.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismene2pol3.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
        </div>
        <div >
        <span>Старији разреди - прво полугодиште</span>
           <img src='../images/pismene1pol1.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismene1pol2.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismene1pol3.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
        </div>
        <div>
        <span>Нижи разреди - друго полугодиште</span>
           <img src='../images/pismeneRazredna1.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismeneRazredna2.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismeneRazredna3.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismeneRazredna4.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismeneRazredna5.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/pismeneRazredna6.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
        </div>
        </div>
    )
}

export default RasporedPismenih;