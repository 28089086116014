const OtvorenaVrata = ()=>{
    return(
        <div className="kontejner">
        <div className='kalendar'>
           <img src='../images/Individualni1.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
           <img src='../images/Individualni2.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
        </div>
        </div>
    )
}

export default OtvorenaVrata;