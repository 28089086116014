const rasporedPredmetna = ()=>{
    return(
        <div className="kontejner">
        <div className='kalendar'>
           <img src='../images/terminiDopunske.jpg' className='img-fluid shadow-4' alt='Индивидуални разговори'  />
        </div>
      
        </div>
    )
}

export default rasporedPredmetna;