const SkolskiOdbor = ()=>{
    return(
        <div>
<div className="stranicaUizradi">
             <img src="../images/stranica.png" alt="Stranica je u izradi."></img>
        </div>
        </div>
    )
}

export default SkolskiOdbor;