const Istorija = ()=>{
    return(
        <div className="kontejner">
           
        <div className="textIstorijat">
            <h3 style={{textAlign:'center'}}>О школи...</h3>
            <h5>
            ОШ „Бранко Радичевић“ најстарија је градска школа у Прибоју. Оскудни
подаци говоре да је почела са радом давне 1888. године у време када је
Прибој био мало гранично насеље према Босни.
Наша школа свој рад везује за три века током којих је имала успоне и
падове, повремене прекиде у раду, али увек добар дух и позитивну
енергију, коју ни ученици ни наставни кадар никада нису губили.
Зграда, у којој сад радимо, учимо и растемо,саграђена је 1964. године , а са
радом је почела 6. септембра у 18 опремљених учионица, на око 2000
квадратних метара простора, а количину знања, које је протекла и још увек
тече овим простором тешко је измерити.
Једина смо градска школа на нашој општини која има велику и опремљену
кухињу, а која у нашој школи постоји од 1973. године .Често уживамо у
дивним специјалитетима наших куварица, а најважније од свега је да се у
школској трпезарији дружимо, кад је потребно едукујемо, али и веселимо
и забављамо.</h5>
  <h5>  Поносни смо и на нашу библиотеку препознатљиву по великом броју
наслова и пријатној атмосфери. У школи имамо и зубну амбуланту у коју
не идемо баш са осмехом, али идемо да би нам осмех био леп
Функционална и савремена фискултурна сала нам је реновирана 2020. уз
помоћ локалне самоуправе и ЕУ ПРО, а исте године почео је са радом и
продужени боравак у којем најмлађи ученици креативно и забавно
проводе време.</h5>
<h5>У циљу што боље енергетске ефикасности 2012. године замењена је стара
дрвена столарија новим ПВЦ прозорима, уз помоћ министарства
енергетике, а уз подршку министарства правде добили смо 2017. нови
кровни покривач. Хладне зимске дане нисмо ни осетили захваљујући
локалној самоуправи која нам је 2017. реконструисала котларницу, тада
смо прешли на грејање на пелет и тако дали свој допринос заштити
животне средине.</h5>
<h5>Посебно само поносни на наш наставни кадар , мада морамо напоменути
да је читав тим квалитетних људи је укључен у рад школе.Наши ученици
доносе дипломе и награде са разних такмичења како у знању, тако и у
уметности и спорту.</h5>
<h5>А када се уморимо од рада чека нас наш Креативни кутак , који се налази у
старој згради школе , коју чувамо као право благо. Можда стара зграда

нема велику материјални вредност, али успомене, историја, емоције и
приче које су уткане у те зидове су непроцењиве.
Кроз нашу школу је прошло више од 400 просветних радника и генерације
и генерације ученика, од којих су неки познати у целој Србији па и шире.
Неки од познатих научних радника, доктора наука, лекара специјалиста,
инжењера, глумаца, уметника …своја прва слова су научили у нашој
школи, а и данас смо познати као школа из које излазе добри ђаци и још
бољи људи.</h5>
<h5>Обележавање значајних датума, организовање разних забавних и
едукативних активности, хуманитарних акција, укључивање у бројна
дешавања и иновације у настави за нас су задовољство, а не обавеза.Били
смо пионири на нашој општини и први почели са применом електронског
дневника.</h5>
<h5>Присетимо се да смо приликом обележавања 100 година школе имали
један од најсавременијих кабинета информатике, хемије и физике,
базен…Тад је откривена и биста нашег великог романтичарског песника
Бранка Радичевића по којем наша школа носи име и чију свестраност,
оригиналност и непоновљивост покушавамо да сачувамо и уткамо у
наше школске дане.</h5>
<h5>Можда сада немамо број ученика као некад, можда нам велики проценат
наставника ради у две и више установа, али сигурно је једно МИ СМО
ШКОЛА СА ВЕЛИКОМ ДУШОМ; ШКОЛА У КОЈОЈ СВИМ
УЧЕНИЦИМА ПОСВЕЋУЈЕМО ИСТУ ПАЖЊУ, ШКОЛА КОЈА ЈЕ
ПРЕПОЗНАТЉИВА ПО ТИМСКОМ ДУХУ И ДРУЖЕЊУ И КОЈОЈ СУ
СВИ ДОБРОДОШЛИ!!!!
            </h5>
        </div>
        </div>
       
    )
}

export default Istorija;