const Timovi = ()=>{
    return(
        <div className="kontejner">
        <div className='timovi'>
           <img src='../images/timovi1.jpg' className='img-fluid shadow-4' alt='Распоред звоњења 2023/2024 године'  />
           <img src='../images/timovi2.jpg' className='img-fluid shadow-4' alt='Распоред звоњења 2023/2024 године'  />
           <img src='../images/timovi3.jpg' className='img-fluid shadow-4' alt='Распоред звоњења 2023/2024 године'  />
           <img src='../images/timovi4.jpg' className='img-fluid shadow-4' alt='Распоред звоњења 2023/2024 године'  />
           <img src='../images/timovi5.jpg' className='img-fluid shadow-4' alt='Распоред звоњења 2023/2024 године'  />
        </div>
        </div>
    )
}

export default Timovi;