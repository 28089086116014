const Kalendar = ()=>{
    return(
        <div className="kontejner">
        <div className='kalendar'>
           <img src='../images/kalendar.png' className='img-fluid shadow-4' alt='Школски календар 2023/2024 године'  />
        </div>
        </div>
    )
}

export default Kalendar;