import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const PredmetnaNastava = ()=>{
    return(
        <div className='kontejnerDokumenata1'>
            <div className='tabela'>
                <h5 style={{padding:'0.5rem 1rem'}}>Запослени у предметној настави:</h5>
        <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Српски језик и књижевност</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Новковић Драгана</td>
          </tr>
          <tr>
            <td>Комарица Биљана</td>
          </tr>
          <tr>
            <td>Ђуровић Милица</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Енглески језик</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Еминић Мирела</td>
          </tr>
          <tr>
            <td>Новаковић Александра</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Немачки језик</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Симовић Бранка</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Руски језик</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Пурић Дана</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Ликовна култура</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Лаловић Драгана</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Музичка култура</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Церовић Горан</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Историја</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Ћирковић Витомир</td>
          </tr>
          <tr>
            <td>Делић Слободан</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Географија</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Пенезић Боре</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Физика</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Милићевић Слађана</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Математика</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Станић Ирена</td>
          </tr>
          <tr>
            <td>Драгутиновић Радош</td>
          </tr>
          <tr>
            <td>Цвркотић Зоран</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Хемија</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Цвркотић Душица</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Биологија</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Обућина Самира</td>
          </tr>
          <tr>
            <td>Клајић Едиб</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Информатика и рачунарство</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Дујовић Александар</td>
          </tr>
          <tr>
            <td>Којадиновић Јелена</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Физичко и здравствено васпитање</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Оташевић Жељко</td>
          </tr>
          <tr>
            <td>Јеремић Стефан</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Техника и технологија</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Цвркотић Зоран</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Верска настава</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Ћирковић Вита</td>
          </tr>
          <tr>
            <td>Хоџић Омер</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Грађанско васпитање</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Комарица Биљана</td>
          </tr>
          <tr>
            <td>Миковић Данијела</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Чувари природе</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Пенезић Боре</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <MDBTable>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Домаћинство</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <td>Пенезић Боре</td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      </div>
      </div>
    )
}

export default PredmetnaNastava;