import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';

const DanPi = ()=>{
    return(
        <div className='kontejner'>
          <div className='textSlida'>
          <p>Данас смо започели богат  и разноврстан програм  прославе Дана школе, који смо назвали  
            "Бранкови разиграни дани". На данашњи дан је рођен наш велики песник Мика Антић, а такође 
            се обелeжава и Дан број Pi(3.14). Био је то леп повод  да организујемо квиз знања и ближе 
            се упознамо са традицијом обележавања броја  Pi, са занимљивим детаљима из живота Мике Антића, 
            да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
          </div>
        <div className='kontejnerSlida'>
             <MDBCarousel showIndicators showControls fade>
      <MDBCarouselItem itemId={1}>
        <img src='../images_vesti/brPi1.jpg' className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
          <p>Данас смо започели богат  и разноврстан програм  прославе Дана школе,који смо назвали  "Бранкови разиграни дани".
На данашњи дан је рођен наш велики песник Мика Антић,а такође се обелeжава и Дан број Pi(3.14).
Био је то леп повод  да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја  Pi, са занимљивим детаљима из живота Мике Антића,да проверимо меморију,знање,али најважније од свега да се лепо дружимо</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={2}>
        <img src='../images_vesti/brPi2.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
          <p>Данас смо започели богат  и разноврстан програм  прославе Дана школе,који смо назвали  "Бранкови разиграни дани".
На данашњи дан је рођен наш велики песник Мика Антић,а такође се обелeжава и Дан број Pi(3.14).
Био је то леп повод  да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја  Pi, са занимљивим детаљима из живота Мике Антића,да проверимо меморију,знање,али најважније од свега да се лепо дружимо</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={3}>
        <img src='../images_vesti/brPi3.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
          <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={4}>
        <img src='../images_vesti/brPi4.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={5}>
        <img src='../images_vesti/brPi5.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={6}>
        <img src='../images_vesti/brPi6.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={7}>
        <img src='../images_vesti/brPi7.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={8}>
        <img src='../images_vesti/brPi8.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={9}>
        <img src='../images_vesti/brPi9.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={10}>
        <img src='../images_vesti/brPi10.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={11}>
        <img src='../images_vesti/brPi11.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={12}>
        <img src='../images_vesti/brPi12.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={13}>
        <img src='../images_vesti/brPi13.jpg' className='d-block w-100' alt='maraton' />
        <MDBCarouselCaption>
        <p>Данас смо започели богат и разноврстан програм прославе Дана школе,који смо назвали "Бранкови разиграни дани".
            На данашњи дан је рођен наш велики песник Мика Антић, а такође се обелeжава и Дан број Pi(3.14). Био је то леп повод
             да организујемо квиз знања и ближе се упознамо са традицијом обележавања броја Pi, са занимљивим детаљима из живота Мике Антића, 
             да проверимо меморију, знање, али најважније од свега да се лепо дружимо.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>

    </MDBCarousel>
        </div>
        </div>
    )
}

export default DanPi;